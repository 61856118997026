import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledServices {
  theme: any;
}

interface IStyledService {
  theme: any;
}

export const StyledService = styled.div<IStyledService>`
  .service-content {
    margin-bottom: ${rem(`48px`)};
    padding-left: 0;
    ${(props) => props.theme.breakpoints.phone`
      width: 100%;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    `}
    ${(props) => props.theme.breakpoints.phablet`
      :not(.content--no-padding) {
        padding-left: ${rem(`64px`)};
      }
    `}
    ${(props) => props.theme.breakpoints.tablet`
      &.last {
        margin-bottom: 0;
      }
    `}
  }
  .illustration {
    display: block !important;
    margin: 0 auto ${rem(`24px`)};
    ${(props) => props.theme.breakpoints.phone`
      display: inline-block;
    `}
    &--mobile-no-margin {
      margin-bottom: 0;
    }
    &--right {
      ${(props) => props.theme.breakpoints.phone`
        float: right;
        margin: 0 0 ${rem(`24px`)} ${rem(`36px`)};
      `}
      ${(props) => props.theme.breakpoints.tablet`
        margin: 0 0 0 ${rem(`72px`)};
      `}
      ${(props) => props.theme.breakpoints.desktop`
        margin: 0 0 0 ${rem(`144px`)};
      `}
    }
    &--left {
      ${(props) => props.theme.breakpoints.phone`
        float: left;
        margin: 0 ${rem(`16px`)} ${rem(`24px`)} 0;
      `}
    }
  }
  .link {
    display: inline-block;
    margin-top: ${rem(`12px`)};
    &__icon {
      margin-left: ${rem(`6px`)};
    }
  }
`;

export const StyledServices = styled.div<IStyledServices>`
  .main-title {
    font-size: 3em;
    ${(props) => props.theme.breakpoints.phone`
      font-size: 4em;
    `}
  }
  .content {
    margin-bottom: ${rem(`48px`)};
  }
`;
