import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { Social } from '@/components/Social';
import Layout, { Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { Seo } from '../../components/SEO';
import { IconTitle } from '../../components/IconTitle';
import { MdContent } from '../../components/MdContent';
import { CallToAction } from '../../components/CallToAction';
import { StyledServices, StyledService } from './styles';

interface IServicesPageTemplate {
  // eslint-disable-next-line react/no-unused-prop-types
  content?: any;
  services: any;
}

interface IServicesPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulPage: any;
    allContentfulService: any;
  };
  location: {};
}

const ServicesPageTemplate = ({ content, services }: IServicesPageTemplate) => {
  const { title, body } = content;
  const { body: mdBody } = body;
  return (
    <>
      <Content restrictWidth setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1" className="main-title">
          {title}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={mdBody} />
        </Text>
      </Content>

      <Content restrictWidth setMaxWidth="90%" className="last">
        {services.map(({ node }) => {
          const {
            excerpt,
            hookId,
            slug,
            icon: nodeIcon,
            image: nodeImage,
            title: iconTitle,
          } = node;
          const icon = getImage(nodeIcon);
          const image = getImage(nodeImage);
          return (
            <StyledService key={uuidv4()}>
              <IconTitle
                id={hookId}
                icon={icon}
                iconAlt={`Icon for ${iconTitle}`}
                title={iconTitle}
              />
              <GatsbyImage
                image={image}
                alt={`Illustration for ${iconTitle}`}
                className="illustration illustration--right"
              />

              <Content
                restrictWidth
                setMaxWidth="90%"
                className="service-content"
              >
                <Text type="body1" tag="div" color="gray_3">
                  <MdContent content={excerpt.excerpt} />
                </Text>

                <div className="link">
                  <Link to={slug}>
                    <Button size="small" disabled={false}>
                      Read more
                      <FontAwesomeIcon
                        size="sm"
                        className="link__icon"
                        icon={faChevronRight}
                      />
                    </Button>
                  </Link>
                </div>
              </Content>
            </StyledService>
          );
        })}
      </Content>

      {/* <CallToAction
        hookId={ctaHookId}
        icon={ctaIcon}
        title={ctaTitle}
        body={ctaBody.ctaBody}
      /> */}
      <Social />
    </>
  );
};

const ServicesPageDesktopTemplate = ({ services }: IServicesPageTemplate) => {
  return (
    <div className="body">
      <Content restrictWidth setMaxWidth="100%" noMargin>
        {services.map(({ node }) => {
          const {
            excerpt,
            hookId,
            icon: nodeIcon,
            image: nodeImage,
            slug,
            title: iconTitle,
          } = node;
          const icon = getImage(nodeIcon);
          const image = getImage(nodeImage);
          return (
            <StyledService key={uuidv4()}>
              <GatsbyImage
                image={image}
                alt={`Illustration for ${iconTitle}`}
                className="illustration illustration--right"
              />
              <IconTitle
                id={hookId}
                icon={icon}
                iconAlt={`Icon for ${iconTitle}`}
                title={iconTitle}
              />
              <Content
                restrictWidth
                setMaxWidth="100%"
                className={`service-content ${
                  slug === `team-augmentation` ? `last` : ``
                }`}
                // noMargin={slug === `team-augmentation`}
              >
                <Text type="body1" tag="div" color="gray_3" className="excerpt">
                  <MdContent content={excerpt.excerpt} />
                </Text>
                <div className="link">
                  <Link to={slug}>
                    <Button size="small" disabled={false}>
                      Read more
                      <FontAwesomeIcon
                        size="sm"
                        className="link__icon"
                        icon={faChevronRight}
                      />
                    </Button>
                  </Link>
                </div>
              </Content>
            </StyledService>
          );
        })}
      </Content>
    </div>
  );
};

const ServicesPage = ({ data, location }: IServicesPage) => {
  const page = get(data, `contentfulPage`);
  const services = get(data, `allContentfulService.edges`);
  const {
    title,
    slug,
    metaDescription,
    metaTitle,
    templateKey,
    body,
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <StyledServices>
      <Layout
        location={location}
        coverImage={hero}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content restrictWidth>
            <Text type="title1" tag="h2" color="white_1">
              {title}
            </Text>
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={body.body} />
            </Text>
          </Content>
        }
        desktopBody={<ServicesPageDesktopTemplate services={services} />}
        desktopCta={
          <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={hero}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <ServicesPageTemplate
            content={{
              title,
              body,
              ctaTitle,
              ctaHookId,
              ctaIcon,
              ctaBody,
            }}
            services={services}
          />
        </Main>
      </Layout>
    </StyledServices>
  );
};

export default ServicesPage;

export const ServicesPageQuery = graphql`
  query ServicesPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      body {
        body
      }
      metaTitle
      metaDescription
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulService(sort: { fields: order }) {
      edges {
        node {
          hookId
          slug
          title
          excerpt {
            excerpt
          }
          icon {
            gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
          }
          image {
            gatsbyImageData(
              layout: FIXED
              height: 216
              quality: 85
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
